import React from "react";

// Import styles
import styles from "./Footer.module.css";

// Import medi
import Logo from "../../assets/logo.png";
import HeadingTwo from "../HeadingTwo";
import ParagraphOne from "../ParagraphOne";

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.mapContainer}>
        <iframe
          className={styles.footerMap}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.691624503457!2d-83.42272672384844!3d42.32777567119596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b4d7e737790b3%3A0xd727dbc8a632e4c1!2s38500%20Webb%20Dr%2C%20Westland%2C%20MI%2048185!5e0!3m2!1sen!2sus!4v1726532027501!5m2!1sen!2sus"
          title="C&R location map"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <p className={styles.mapCaption}>38500 Webb Dr Westland, MI 48185</p>
      </div>

      <div className={styles.logoContainer}>
        <img className={styles.logo} src={Logo} alt="Logo" />
        <p className={styles.companyNameParagraph}>
          Made by{" "}
          <a
            className={styles.companyNameLink}
            href="https://whalemedia.us/"
            target="blank"
          >
            Whale Media
          </a>{" "}
          🐋
        </p>
      </div>

      <div className={styles.hoursContainer}>
        <HeadingTwo>Hours</HeadingTwo>
        <ParagraphOne>MONDAY 9AM-6PM</ParagraphOne>
        <ParagraphOne>TUESDAY 9AM-6PM</ParagraphOne>
        <ParagraphOne>WEDNESDAY 9AM-6PM</ParagraphOne>
        <ParagraphOne>THURSDAY 9AM-6PM</ParagraphOne>
        <ParagraphOne>FRIDAY 9AM-6PM</ParagraphOne>
        <ParagraphOne>SATURDAY 9AM-1PM</ParagraphOne>
        <ParagraphOne>SUNDAY CLOSED</ParagraphOne>
      </div>
    </div>
  );
}

export default Footer;
